import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import {
  PARENT_APP_DEF_ID,
  PAGE_DATA,
  MENUS_SHOWCASE_APP_DEF_ID,
  OLO_APP_DEF_ID,
  OPTIMUS_ORIGIN_TYPE,
} from 'root/utils/consts';
import { createAppPage, setPagesState } from 'root/utils/createPage';
import type {
  EditorReadyFn,
  FlowEditorSDK,
  GetAppManifestFn,
  OwnerLogger,
  TFunction,
  EditorScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { EditorType } from '@wix/platform-editor-sdk';
import {
  type Exports,
  type AppManifest,
  type InitialAppData,
  type AppExposedApis,
  type EditorReadyOptions,
} from '@wix/platform-editor-sdk';
import { FedopsLogger } from './utils/monitoring/FedopsLogger';
import {
  menusEditorMenuSettingsPanelAction,
  menusEditorShowHidePlaceholderImage,
  menusEditorPlaceholderImageDesignAction,
} from '@wix/bi-logger-restaurants/v2';
import type {
  menusEditorMenuSettingsPanelActionParams,
  menusEditorShowHidePlaceholderImageParams,
  menusEditorPlaceholderImageDesignActionParams,
} from '@wix/bi-logger-restaurants/v2/types';
import { initGfppEventListener } from './editor.app.services/gfppEventListener';
import { setMenusMobileGfpp, setMenusPresetsData } from './utils/manifestUtils';
import { initPageActionsEventListener } from './editor.app.services/pageActionsEventListener';
import type { AddPageProgressBarTexts } from './components/Menu/panels/MenuSettings/types';
import { initPresetChangedEventListener } from './editor.app.services/presetChangedEventListener';
import { BiReporter } from './utils/BiReporter';
import { MENU_LAYOUT_ORIGIN, MENU_PRESETS_PANEL } from './utils/bi';
import { isMobileViewport, getIsStudio } from './utils/commonUtils';
import { initDeviceSwitchEventListener } from './editor.app.services/deviceSwitchEventListener';
import { configureMyBusinessActions } from './editor.app.services/configureMyBusinessActions';

const TOKEN = 'token';
let flowAPI: EditorScriptFlowAPI;

export const editorReady: EditorReadyFn = async (editorSDK, appDefId, options, _flowAPI) => {
  flowAPI = _flowAPI;
  const t = flowAPI.translations.t as TFunction;
  const fedopsLogger = new FedopsLogger(flowAPI.fedops);
  const editorType = options.origin.type;

  if (options.firstInstall) {
    try {
      const isParentAppInstalled = await editorSDK.document.application.isApplicationInstalled(TOKEN, {
        appDefinitionId: PARENT_APP_DEF_ID,
      });
      if (!isParentAppInstalled) {
        await editorSDK.document.application.add(TOKEN, {
          appDefinitionId: PARENT_APP_DEF_ID,
        });
      }
    } catch (err) {
      const error = typeof err === 'string' ? new Error(err) : (err as Error);
      flowAPI.reportError(error);
    }

    fedopsLogger.menusShowcaseFirstInstallStarted();
    // Hotfix for fake progress bar is open error - should investigate
    let isOpenProgressBarSuccess = true;
    const isResponsive = editorType === EditorType.Responsive;

    const shouldOpenProgressBar = options.origin.info?.appDefinitionId !== OLO_APP_DEF_ID && !isResponsive;

    if (shouldOpenProgressBar) {
      try {
        await editorSDK.editor.openProgressBar(TOKEN, {
          title: t('app.loader.label'),
          totalSteps: 1,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('openProgressBar failure', e);
        isOpenProgressBarSuccess = false;
      }
    }

    const isOptimusOrigin = (options.origin.type as unknown) === OPTIMUS_ORIGIN_TYPE;

    const { widgetRef, pageRef } = await createAppPage({
      editorSDK,
      appDefId,
      pageData: PAGE_DATA,
      pageTitle: t(PAGE_DATA.title),
      isResponsive,
      shouldAddMenuItem: true,
      shouldNavigateToPage: isResponsive,
      hidePage: isOptimusOrigin,
    });
    if (shouldOpenProgressBar) {
      try {
        await editorSDK?.editor.updateProgressBar('', {
          currentStep: 1,
          stepTitle: t('app.completed.label'),
        });
      } catch (e) {
        flowAPI.reportError(e as Error);
      }
    }
    fedopsLogger.menusShowcaseFirstInstallEnded();

    if (isOpenProgressBarSuccess && shouldOpenProgressBar) {
      try {
        await editorSDK.document.pages.navigateTo(TOKEN, { pageRef });
        widgetRef && (await editorSDK.editor.selection.selectComponentByCompRef(TOKEN, { compsToSelect: [widgetRef] }));

        await editorSDK.editor.closeProgressBar(TOKEN, {});
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('closeProgressBar failure', e);
      }
    }
  }
  const isStudio = getIsStudio(options);
  await initGfppEventListener({ flowAPI, editorSDK, fedopsLogger, editorType });
  await initPageActionsEventListener({ editorSDK, flowAPI });
  await initPresetChangedEventListener({ editorSDK, flowAPI });
  isStudio && (await initDeviceSwitchEventListener({ editorSDK }));

  await setPagesState({ editorSDK });
};

export const getAppManifest: GetAppManifestFn = async (options, editorSDK, context, flowAPI): Promise<AppManifest> => {
  const { appManifestBuilder, ...appData } = options;
  const baseManifest = await fetchWidgetsStageData(appData as InitialAppData);
  const { translations } = flowAPI;
  const t = translations.t as TFunction;
  const isStudio = getIsStudio(context);

  appManifestBuilder.configureManagementActions((managementActionsBuilder) => {
    configureMyBusinessActions(editorSDK, flowAPI, managementActionsBuilder);
  });

  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  setMenusMobileGfpp(manifest);

  const isMobile = await isMobileViewport(editorSDK);
  setMenusPresetsData(manifest, isMobile, isStudio, t);

  return manifest;
};

export const exports: Exports = (editorSDK: FlowEditorSDK, context: EditorReadyOptions): AppExposedApis => {
  const biLogger = context.essentials.biLoggerFactory().logger();
  const fedopsLogger = new FedopsLogger(context.essentials.fedopsLogger);
  const editorType = context.origin.type;
  const isResponsive = editorType === EditorType.Responsive;

  return {
    public: {
      uninstall: () => editorSDK.application.uninstall('', { openConfirmation: true }), // TODO: https://jira.wixpress.com/browse/RST-6288
    },
    private: {
      reportMenuSettingsBi: (params: menusEditorMenuSettingsPanelActionParams) =>
        biLogger.report(menusEditorMenuSettingsPanelAction(params)),
      reportMenuPlaceholderImageBi: (params: menusEditorShowHidePlaceholderImageParams) =>
        biLogger.report(menusEditorShowHidePlaceholderImage(params)),
      reportMenuPlaceholderImageDesignBi: (params: menusEditorPlaceholderImageDesignActionParams) =>
        biLogger.report(menusEditorPlaceholderImageDesignAction(params)),
      createMenuPage: async () => {
        const t = flowAPI.translations.t as TFunction;
        const progressBarTexts: AddPageProgressBarTexts = {
          title: t('settings-panel.add-page.progress-bar.title'),
          inProgress: t('settings-panel.add-page.progress-bar.in-progress'),
          completed: t('settings-panel.add-page.progress-bar.completed'),
        };
        await editorSDK?.editor.openProgressBar('', {
          title: progressBarTexts.title,
          stepTitle: progressBarTexts.inProgress,
          totalSteps: 1,
        });

        const pageTitle = t(PAGE_DATA.title);
        const { pageRef } = await createAppPage({
          editorSDK,
          appDefId: MENUS_SHOWCASE_APP_DEF_ID,
          pageData: PAGE_DATA,
          pageTitle,
          isResponsive,
          shouldAddMenuItem: true,
          shouldNavigateToPage: true,
        });

        await editorSDK?.editor.updateProgressBar('', {
          currentStep: 1,
          stepTitle: progressBarTexts.completed,
        });
        await editorSDK?.editor.closeProgressBar('', {});
        // @ts-expect-error
        await editorSDK?.editor.openPagesPanel('', {
          pageRef,
          renameEnabled: true,
        });
      },
      reportFedopsOpenMenuSettingsPanelEnded: fedopsLogger.openMenuSettingsPanelEnded,
      reportFedopsOpenSettingsPanelEnded: fedopsLogger.openSettingsPanelEnded,
    },
    editor: {
      getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
        const biReporter = new BiReporter(biLogger as OwnerLogger, widgetRef.id);
        await biReporter.init({ editorSDK });
        biReporter.reportMenuWidgetPanelBi(MENU_LAYOUT_ORIGIN, MENU_PRESETS_PANEL);
        return (
          await editorSDK.application.appStudioWidgets.getPreset('', {
            componentRef: widgetRef,
          })
        ).style;
      },
    },
  };
};
